@tailwind base;
@tailwind components;
@tailwind utilities;

/* File: font.css */
@font-face {
	font-family: 'OCR-A';
	src: local('OCR-A'), url('./assets/fonts/OCR-A.ttf') format('truetype');
}

@font-face {
	font-family: 'ocr-a-bt';
	src: local('ocr-a-bt'), url('./assets/fonts/ocr-a-bt.ttf') format('truetype');
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	height: 100%;
	width: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.rmsc {
	--rmsc-main: transparent !important;
	--rmsc-hover: #f1f3f5;
	--rmsc-selected: #e2e6ea;
	--rmsc-border: transparent !important;
	--rmsc-gray: #aaa;
	--rmsc-bg: transparent !important;
	--rmsc-p: 10px;
	/* Spacing */
	--rmsc-radius: 0px !important;
	/* Radius */
	--rmsc-h: 100% !important;
	/* Height */
}

.dropdown-container {
	height: 100%;
	background-color: white;
}

.dropdown-content {
	border-radius: 6px;
	overflow: hidden;
	background-color: white;
	padding-top: 0px !important;
	margin-top: 8px !important;
	width: 100%;
	box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px,
		rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
		rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
	width: 100%;
}

/* card input css */
span.cinp {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
}

span.cinp input {
	outline: none;
	height: 100%;
	width: 100%;
}

span.cinp:focus-within {
	outline: 2px solid transparent;
	outline-offset: 2px;
	--tw-ring-inset: var(--tw-empty,
			/*!*/
			/*!*/
		);
	--tw-ring-offset-width: 0px;
	--tw-ring-offset-color: #fff;
	--tw-ring-color: rgb(99, 102, 241);
	--tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
	--tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
	box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
		var(--tw-shadow, 0 0 #0000);
	border-color: rgb(99, 102, 241);
}

span.cinp label {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.remove_boxSdow__input {
	box-shadow: none !important;
}